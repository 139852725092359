import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import { type FAQGroup, FAQGroupUtils } from '../components/FAQ';
import { useCloseRoutedGamePackEditor } from '../components/Game/GamePack';
import { GamePackEditor } from '../components/Game/GamePack/GamePackEditor';
import { GamePackV2Editor } from '../components/Game/GamePack/GamePackV2Editor';
import { GamePackUtils } from '../components/Game/GamePack/utils';
import { apiService } from '../services/api-service';
import { type GamePackShowcaseCard } from '../types/game';
import { fromDTOGamePack } from '../utils/api-dto';
import { assertExhaustive } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () =>
      apiService.gamePack.getGamePackById(id, {
        blocks: true,
        brands: true,
      }),
    action.request.url,
    { admin: true }
  );

  const gamePack = fromDTOGamePack(resp.data.gamePack);

  const faqGroups: FAQGroup[] = [];
  const anonymousFAQGroups: FAQGroup[] = [];
  const showcaseCards: GamePackShowcaseCard[] = [];
  const fagGroupIds = gamePack.marketingSettings?.faqGroupIds ?? [];
  const anonymousFAQGroupIds =
    gamePack.marketingSettings?.anonymousFAQGroupIds ?? [];
  const showcaseCardIds = gamePack.marketingSettings?.showcaseCardIds ?? [];
  const sharedAssetIds = [
    ...fagGroupIds,
    ...anonymousFAQGroupIds,
    ...showcaseCardIds,
  ];
  if (sharedAssetIds.length > 0) {
    const sharedAssets = await apiService.media
      .querySharedAsset({
        type: 'by-ids',
        ids: sharedAssetIds.join(','),
        size: 100,
      })
      .next();

    for (const groupId of fagGroupIds) {
      const asset = sharedAssets.find((a) => a.id === groupId);
      if (!asset) continue;
      faqGroups.push(FAQGroupUtils.FromSharedAsset(asset));
    }

    for (const groupId of anonymousFAQGroupIds) {
      const asset = sharedAssets.find((a) => a.id === groupId);
      if (!asset) continue;
      anonymousFAQGroups.push(FAQGroupUtils.FromSharedAsset(asset));
    }

    for (const cardId of showcaseCardIds) {
      const asset = sharedAssets.find((a) => a.id === cardId);
      if (!asset) continue;
      showcaseCards.push(GamePackUtils.ToShowcaseCard(asset));
    }
  }

  return {
    gamePack: fromDTOGamePack(resp.data.gamePack),
    brands: resp.data.brands ?? [],
    blocks: resp.data.blocks ?? [],
    faqGroups,
    anonymousFAQGroups,
    showcaseCards,
  };
}

export const Component = () => {
  const {
    gamePack,
    brands,
    blocks,
    faqGroups,
    anonymousFAQGroups,
    showcaseCards,
  } = useLoaderData<typeof clientLoader>();
  const closeEditor = useCloseRoutedGamePackEditor();

  // _blocks_ include all that will be used in a game, include all predefined
  // blocks from brands. _selectedBlocks_ are those that are actually added to
  // the game pack.
  const selectedBlocks = blocks.filter((b) =>
    gamePack.childrenIds?.includes(b.id)
  );

  switch (gamePack.version) {
    case EnumsGamePackVersion.GamePackVersionV1:
      return (
        <GamePackEditor pack={gamePack} onClose={closeEditor} context='edit' />
      );
    case EnumsGamePackVersion.GamePackVersionV2:
      return (
        <GamePackV2Editor
          pack={gamePack}
          brands={brands}
          blocks={selectedBlocks}
          faqGroups={faqGroups}
          anonymousFAQGroups={anonymousFAQGroups}
          showcaseCards={showcaseCards}
          onClose={closeEditor}
        />
      );

    default:
      assertExhaustive(gamePack.version);
      return <></>;
  }
};
